import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

export default function BlogDetails (){
    return(<>
    
      {/* Page Title */}
      <div className="page-title-area">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="title-content">
                            <h2>Blog Details</h2>
                            <ul>
                                <li>
                                <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <span>Blog Details</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* End Page Title */}

        {/* Blog Details */}
        <div className="blog-details-area ptb-100">
            <div className="container">
                <div className="row">

                    <div className="col-lg-8">
                        <div className="details-item">

                            <div className="details-img">
                                <img src="images/blog/blog-details1.jpg" alt="Details" />
                                <h2>Largest Demand Partnership Building With Agency</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the electronic.</p>
                            </div>

                            <div className="details-quote">
                                <blockquote>
                                    <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</h3>
                                    <i className='bx bxs-quote-alt-left bx-flip-horizontal'></i>
                                </blockquote>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the electronic suspendisse ultrices gravida.</p>
                            </div>

                            <div className="details-grow">
                                <h3>How To Grow Your Business</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo</p>
                                <ul>
                                    <li>Complete Guide To Mechanical</li>
                                    <li>Business & Consulting Agency</li>
                                    <li>Award Wining Business</li>
                                </ul>
                                <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classNameical Latin. Mliterature from 45 BC, making it over 2000 years old. Lorealintock The Extremes of Good and dummy Eviyr. The standard chunk of Lorem Ipsum used sfertyui ince the 1500s is reproduced below for those interested. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium dom, totam rem ape Donec ornare making it over 2000 years old.</p>
                            </div>

                            <div className="details-form">
                                <h3>Leave A Reply</h3>
                                <p>Your email address will not be published. Required fields are marked*</p>
                                <form>
                                    <div className="row">

                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <label>Comment</label>
                                                <textarea id="your-comment" rows="8" className="form-control"></textarea>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label>Name*</label>
                                                <input type="text" className="form-control" />
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label>Email*</label>
                                                <input type="email" className="form-control" />
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <button type="submit" className="btn common-btn">
                                                Post A Comment
                                                <span></span>
                                            </button>
                                        </div>

                                    </div>
                                </form>
                            </div>

                            <div className="details-comment">
                                <h3>Comments</h3>
                                <ul>
                                    <li>
                                        <img src="images/blog/blog-details2.jpg" alt="Details" />
                                        <h4>Jimy Pearson</h4>
                                        <span>January 23, 2020</span>
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit tempore dignissimos dolor suscipit culpa earum voluptates corporis tenetur dolorum perferendis! Quisquam nesciunt non inventore repellendus nam ab aperiam saepe distinctio.</p>
                                        <a href="#">Reply</a>
                                    </li>
                                    <li>
                                        <img src="images/blog/blog-details3.jpg" alt="Details" />
                                        <h4>Tom Henry</h4>
                                        <span>January 24, 2020</span>
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit tempore dignissimos dolor suscipit culpa earum voluptates corporis tenetur dolorum perferendis! Quisquam nesciunt non inventore repellendus nam ab aperiam saepe distinctio.</p>
                                        <a href="#">Reply</a>
                                    </li>
                                    <li>
                                        <img src="images/blog/blog-details4.jpg" alt="Details" />
                                        <h4>Jac Jacson</h4>
                                        <span>January 25, 2020</span>
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit tempore dignissimos dolor suscipit culpa earum voluptates corporis tenetur dolorum perferendis! Quisquam nesciunt non inventore repellendus nam ab aperiam saepe distinctio.</p>
                                        <a href="#">Reply</a>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="widget-area">

                            <div className="services widget-item">
                                <h3>Services List</h3>
                                <ul>
                                    <li>
                                        <a href="#">
                                            Cash Investment
                                            <i className='bx bx-right-arrow-alt'></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            Personal Insurance
                                            <i className='bx bx-right-arrow-alt'></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            Education Loan
                                            <i className='bx bx-right-arrow-alt'></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            Financial Planning
                                            <i className='bx bx-right-arrow-alt'></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div className="popular widget-item">
                                <h3>Popular Post</h3>
                                <div className="popular-inner">
                                    <ul className="align-items-center">
                                        <li>
                                            <img src="images/blog/blog-thumb1.jpg" alt="Details" />
                                        </li>
                                        <li>
                                            <span>30 January, 2020</span>
                                            <a href="#">Financial Planning</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="popular-inner">
                                    <ul className="align-items-center">
                                        <li>
                                            <img src="images/blog/blog-thumb2.jpg" alt="Details" />
                                        </li>
                                        <li>
                                            <span>17 July, 2020</span>
                                            <a href="#">Financial Planning</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="popular-inner">
                                    <ul className="align-items-center">
                                        <li>
                                            <img src="images/blog/blog-thumb3.jpg" alt="Details" />
                                        </li>
                                        <li>
                                            <span>18 Dec, 2020</span>
                                            <a href="#">Financial Planning</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="contact widget-item">
                                <h3>Contact</h3>
                                <form>
                                    <div className="form-group">
                                        <input type="email" className="form-control" placeholder="Your email" />
                                    </div>
                                    <div className="form-group">
                                        <textarea id="your-message" rows="8" className="form-control" placeholder="Message"></textarea>
                                    </div>
                                    <button type="submit" className="btn common-btn">
                                        Send Message
                                        <span></span>
                                    </button>
                                </form>
                            </div>

                            <div className="consultation">
                                <img src="images/services/service-details4.jpg" alt="Details" />
                                <div className="inner">
                                    <h3>Need Any Consultation</h3>
                                    <a className="common-btn" href="contact.html">
                                        Send Message
                                        <span></span>
                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
        {/* End Blog Details */}
    </>)
}